import styled from "styled-components";

export const Section = styled.section`
  margin-top: -100px;
  padding: max(160px, 11.4vw) 0 max(170px, 12vw);
  position: relative;
  overflow-x: hidden;
`;

export const Title = styled.h1`
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 40px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 209px 209px;
  grid-gap: 26px;
`;

export const Column = styled.div`
  max-width: 45%;
`;

export const Image = styled.img`
  width: 49vw;
  object-fit: cover;
  position: absolute;
  object-position: top center;
  left: 53%;
  top: 0;
  z-index: -1;
  height: 100%;
`;

export const ButtonsSpacer = styled.div`
  margin-top: 40px;
  height: 50px;
`;
