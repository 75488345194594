import React from "react";
import Link from "next/link";
import Routes from "lib/routes/app";
import { Container } from "components/Common/Grid";
import bulbIcon from "assets/icons/bulb.svg";
import globeIcon from "assets/icons/globe.svg";
import backgroundIcon from "assets/icons/item-info-bg.png";
import schoolIcon from "assets/icons/school.svg";
import {
  Column,
  ItemsInfoBackgroundIcon,
  Section,
  Paragraph,
  Title,
  ItemInfoBox,
  ItemInfoIcon,
  ButtonsWrapper,
  ItemsContainer,
  AccountButton,
} from "./styles";

const data = {
  intro: "Here you will find free access to more than 1000 items.",
  title: "The items:",
  itemList: [
    {
      icon: schoolIcon,
      description:
        "Are appropriate for elementary, middle, and high school students.",
    },
    {
      icon: globeIcon,
      description:
        "Test student understanding in the earth, life, and physical sciences, and the nature of science.",
    },
    {
      icon: bulbIcon,
      description: "Test for common misconceptions as well as correct ideas.",
    },
  ],
  action: {
    text: "To get full access to all items in the platform create account",
    url: "/app/signup",
  },
};

export default function ItemsInfo() {
  return (
    <Section>
      <Container>
        <Column>
          <Paragraph font="primary" weight="medium">
            {data.intro}
          </Paragraph>
          <Title>{data.title}</Title>
        </Column>
        <ItemsContainer>
          {data.itemList.map((item, i) => (
            <ItemInfoBox key={i}>
              <ItemInfoIcon src={item.icon} alt={item.description} />
              <Paragraph font="primary"> {item.description} </Paragraph>
            </ItemInfoBox>
          ))}
        </ItemsContainer>
        <ButtonsWrapper>
          <Paragraph font="primary" weight="medium">
            {data.action.text}
          </Paragraph>
          <Link passHref href={Routes.SignUp}>
            <AccountButton as="a" secondary>
              Create account
            </AccountButton>
          </Link>
        </ButtonsWrapper>
      </Container>
      <ItemsInfoBackgroundIcon
        src={backgroundIcon}
        alt={" Circles with Dots"}
      />
    </Section>
  );
}
