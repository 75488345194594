import React from "react";
import { Flex } from "components/Common/Grid";
import { P } from "components/Common/typography";
import ItemBankIcon from "assets/icons/bank.svg";
import resultIcon from "assets/icons/result.svg";
import studentIcon from "assets/icons/student.svg";
import testIcon from "assets/icons/test.svg";
import studentImage from "assets/images/student.jpg";
import {
  Section,
  Title,
  FeatureIcon,
  FeatureBox,
  FeaturesContainer,
  Image,
} from "./styles";

const data = {
  title: "The website also includes:",
  features: [
    {
      icon: studentIcon,
      description:
        "Data on how well U.S. students are doing in science and where they are having difficulties, broken out by gender, English language learner status, and whether the students are in middle school or high school.",
    },
    {
      icon: ItemBankIcon,
      description:
        "“My Item Bank,” a feature that allows you to select, save, and print items and answer keys.",
    },
    {
      icon: testIcon,
      description:
        "A feature that allows you to create and take tests online using items from the item collection.",
    },
    {
      icon: resultIcon,
      description:
        "Results of two special projects, one on energy (ASPECt) and one on evolution.",
    },
  ],
  action: {
    text: "To get full access to all items in the platform create account",
    url: "#",
  },
};

export default function Features() {
  return (
    <Section>
      <div>
        <Image src={studentImage} alt={"Student holding books"} />
      </div>
      <Flex.Row align="center">
        <FeaturesContainer>
          <Title>{data.title}</Title>
          {data.features.map((feature, i) => (
            <FeatureBox key={i}>
              <FeatureIcon
                src={feature.icon}
                alt={`${feature.description} Icon`}
              />
              <P font="primary">{feature.description}</P>
            </FeatureBox>
          ))}
        </FeaturesContainer>
      </Flex.Row>
    </Section>
  );
}
