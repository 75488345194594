import styled from "styled-components";
import media from "lib/theme/media";
import Button from "components/Common/Button";
import { H2, P } from "components/Common/typography";

export const Section = styled.section`
  padding: max(5rem, 1vw) 0 max(5rem, 1vw);
  background-color: ${({ theme }) => theme.colors["primary"]};
  position: relative;
  z-index: 1;
`;
export const Paragraph = styled(P)`
  line-height: 1.2;
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors["text_white"]};
`;
export const Title = styled(H2)`
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors["text_white"]};
  padding-bottom: 0.5rem;
`;

export const Column = styled.div`
  max-width: 100%;
  @media ${media.medium} {
    max-width: 45%;
  }
`;
export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.875rem;
  grid-row-gap: 0;
  margin-bottom: 2rem;
  @media ${media.medium} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 7rem;
    grid-row-gap: 0;
  }
`;
export const ItemInfoBox = styled.div`
  margin: 2rem 0;
  width: 90%;
`;
export const ItemInfoIcon = styled.img`
  padding: 2rem;
  width: 6.375rem;
  height: 6.375rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors["icon_background"]};
  @media ${media.medium} {
    padding: 2.5rem;
    width: 9.375rem;
    height: 9.375rem;
    margin-bottom: 3rem;
  }
`;
export const ButtonsWrapper = styled.div`
  margin-top: 2.5rem;
  display: block;
  width: 100%;
  @media ${media.medium} {
    margin-top: 2.5rem;
    display: flex;
    width: 66.5%;
    justify-content: space-between;
  }
`;
export const AccountButton = styled(Button)`
  padding: 1.0625rem 2.9rem;
`;

export const ItemsInfoBackgroundIcon = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  padding: 1rem 0;
  z-index: -1;
  height: 100%;
`;
