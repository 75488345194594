import styled, { css } from "styled-components";

interface TypographyProps {
  light?: boolean;
  ultraLight?: boolean;
  darkBlue?: boolean;
  white?: boolean;
  font?: Font;
  weight?: FontWeight;
  bold?: boolean;
  semiBold?: boolean;
  align?: Align;
  css?: any;
  style?: any;
  padding?: string;
}

type Font = "primary" | "body";
type FontWeight = "semiBold" | "bold" | "regular" | "medium" | "light";
type Align = "left" | "center" | "right";

const FontWeights: { [key: string]: number } = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  semiBold: 600,
};

const headingBase = (props: any) => css`
  color: ${applyColor(props)};
  text-align: ${applyTextAlign};
  font-family: ${props.theme.fonts[props.font ?? "heading"]};
  font-weight: ${FontWeights[(props.weight as string) ?? "bold"]};
  padding: ${applyPadding};
`;

export const H1 = styled.h1<TypographyProps>`
  font-size: 5.2rem;
  ${headingBase}
`;

export const H2 = styled.h2<TypographyProps>`
  font-size: 2.25rem;
  ${headingBase};
  padding-bottom: 1rem;
`;

export const H3 = styled.h3<TypographyProps>`
  font-size: 1.625rem;
  ${headingBase}
`;

export const H4 = styled.h4<TypographyProps>`
  font-size: 1.4rem;
  ${headingBase}
`;

export const H5 = styled.h5<TypographyProps>`
  font-size: 1.2rem;
  ${headingBase}
`;

export const H6 = styled.h6<TypographyProps>`
  font-size: 1rem;
  ${headingBase}
`;

export const P = styled.p<TypographyProps>`
  font-size: 1rem;
  color: ${applyColor};
  text-align: ${applyTextAlign};
  padding: ${applyPadding};

  font-family: ${({ theme, font }) => theme.fonts[font ?? "body"]};
  font-weight: ${({ weight }) => FontWeights[weight ?? "regular"]};

  overflow-wrap: anywhere;

  & > a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const Text = styled.div<TypographyProps | any>`
  color: ${applyColor};
  text-align: ${applyTextAlign};
  padding: ${applyPadding};
  font-family: ${({ theme, font }) => theme.fonts[font ?? "body"]};
  font-weight: ${({ weight }) => FontWeights[weight ?? "regular"]};
  width: 100%;

  img {
    margin-bottom: 1rem;
    display: initial;
    max-width: 100%;
    max-height: 100%;
    // height: auto;
    // width: auto;
  }

  p,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  table {
    margin-bottom: 1em;
  }

  td {
    border-color: ${applyColor};
  }

  video,
  iframe,
  img {
    max-width: 100%;
  }

  video {
    height: auto;
  }

  figcaption {
    font-size: 10pt;
  }

  figure {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    list-style: disc outside none;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.125em;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }
`;

export const Ul = styled.ul`
  list-style: disc;
  padding-left: 20px;
`;

export const Li = styled.li<TypographyProps>`
  font-size: 1rem;
  color: ${applyColor};
  text-align: ${applyTextAlign};
  padding: ${applyPadding};

  font-family: ${({ theme, font }) => theme.fonts[font ?? "body"]};
  font-weight: ${({ weight }) => FontWeights[weight ?? "regular"]};
`;

function applyColor(props: any) {
  if (props.light) return props.theme.colors.text_light;
  if (props.ultraLight) return props.theme.colors.text_ultra_light;
  if (props.darkBlue) return props.theme.colors.text_dark_blue;
  if (props.white) return props.theme.colors.text_white;
  return props.theme.colors.text_dark;
}

function applyTextAlign({ align }: any) {
  return align ?? "left";
}

function applyPadding({ padding }: any) {
  return padding || "initial";
}
