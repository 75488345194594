import React from "react";
import Link from "next/link";
import { useAuthPublic } from "lib/hooks/useAuthPublic";
import Routes from "lib/routes/app";
import Button from "components/Common/Button";
import { Container } from "components/Common/Grid";
import { P } from "components/Common/typography";
import HeroImage from "assets/images/hp_hero.jpg";
import {ButtonsSpacer, ButtonsWrapper, Column, Image, Section, Title} from "./styles";

const copy = {
  title: "Welcome to the BSCS Assessment Website",
  description: "",
  // description:
  //   "Result of more than a decade of research and development " +
  //   "a long-term science education reform initiative of the American Association for the Advancement of Science.",
};

export default function Hero() {
  const user = useAuthPublic();

  return (
    <Section>
      <Container>
        <Column>
          <Title>{copy.title}</Title>
          {copy.description && <P font="primary">{copy.description}</P>}

          {(user !== undefined && !user) ? (
            <ButtonsWrapper>
              <Link href={Routes.SignUp} passHref>
                <Button as="a" primary>
                  Register
                </Button>
              </Link>
              <Link href={Routes.SignIn} passHref>
                <Button as="a" transparent>
                  Sign In
                </Button>
              </Link>
            </ButtonsWrapper>
          ) : <ButtonsSpacer />}
        </Column>
      </Container>
      <Image src={HeroImage} alt="blue backpack with books" />
    </Section>
  );
}
