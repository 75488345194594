import styled from "styled-components";
import media from "lib/theme/media";
import { H2 } from "components/Common/typography";

export const Section = styled.section`
  display: block;
  @media ${media.medium} {
    position: relative;
    z-index: 1;
    display: flex;
  }
`;
export const Title = styled(H2)`
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors["blue_dark"]};
  margin-bottom: 3rem;
`;

export const FeaturesContainer = styled.div`
  padding: 2rem;
  @media ${media.medium} {
    padding: 6rem;
  }
`;
export const FeatureBox = styled.div`
  display: grid;
  grid-template-columns: 0.08fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0;
  :first-of-type {
    margin-bottom: 3rem;
  }
  @media ${media.medium} {
    grid-template-columns: 0.08fr 475px;
  }
`;
export const FeatureIcon = styled.img`
  padding: 0.7rem;
  width: 3.125rem;
  height: 3.188rem;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors["primary"]};
`;
export const Image = styled.img`
  width: 100vw;
  object-fit: cover;
  object-position: top center;
  left: 0;
  top: 0;
  height: 100%;
  @media ${media.medium} {
    width: 40vw;
  }
`;
