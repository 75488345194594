import React from "react";
// import CTA from "components/Public/Homepage/CTA";
import Features from "components/Public/Homepage/Features";
import Hero from "components/Public/Homepage/Hero";
import ItemsInfo from "components/Public/Homepage/ItemsInfo";
import { PublicLayout as Layout } from "../components/Layout";

function IndexPage(): JSX.Element {
  return (
    <Layout buttonTextWhite centerLinks={false}>
      <Hero />
      <ItemsInfo />
      <Features />
      {/*<CTA />*/}
    </Layout>
  );
}

export default IndexPage;
